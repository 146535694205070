import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { VenusNotifier } from '../helper/VenusNotifier';
import { CheckBoxComponent } from '../../components/common/CheckBoxComponent';
import { ControlType, DataType, OrganizerProcessPage } from 'src/Organizer/models/OrganizerComponentModels';

interface ISignerTooltipProps {
    control: any;
    initialControl: any;
    showState: boolean;
    onHide: () => void;
    onUpdateToolTipControl: (control: any) => void;
    submitButtonClick: (control: any, pageNo: number, documentGuid: string) => void;
    content?: {
        header: string,
        title: string,
        tooltipContent: string
    };
    controlType?: ControlType;
    dataType: DataType,
    pageNo: number,
    documentGuid: string,
    pageType:OrganizerProcessPage
}

interface ISignerTooltipState {
    lastControl: any
    control: any;
    pageNo: number;
    documentGuid: string;
}

export class SignerTooltipPopup extends React.Component<ISignerTooltipProps, ISignerTooltipState>{
    constructor(props: ISignerTooltipProps) {
        super(props);
        this.state = {
            lastControl: null,
            control: props.initialControl,
            pageNo: 0,
            documentGuid: ''
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISignerTooltipProps) {
        this.setState({
            lastControl: null,
            control: nextProps.control,
            pageNo: nextProps.pageNo,
            documentGuid: nextProps.documentGuid
        });
    }

    private onCancelButtonClick = () => {
        this.props.onHide();
        if(this.props.pageType===OrganizerProcessPage.EngagementLetter){
            if (this.state.lastControl === null) {
                this.props.submitButtonClick(this.state.control, this.state.pageNo, this.state.documentGuid);
            }
            else {
                this.props.submitButtonClick(this.state.lastControl, this.state.pageNo, this.state.documentGuid);
            }
        }
       else{
            this.setState({ control: this.props.control });
       }
       
    }

    private onSaveButtonClick = () => {
        const { control: { tooltip } } = this.state;
        const { controlType, dataType } = this.props;
        const { Textbox, Signature, SignatureCheckBoxControl, SignatureRadioButtonControl } = ControlType;
        if (controlType && controlType !== Textbox && dataType != DataType.Text && controlType !== Signature) {
            if (controlType == SignatureCheckBoxControl &&
                tooltip >= 1 && tooltip <= 5 && tooltip % 1 === 0) {
                this.props.submitButtonClick(this.state.control, this.state.pageNo, this.state.documentGuid);
                this.props.onHide();
            }
            else if (controlType == SignatureRadioButtonControl) {
                if (tooltip >= 2 && tooltip <= 5 && tooltip % 1 === 0) {
                    this.props.submitButtonClick(this.state.control, this.state.pageNo, this.state.documentGuid);
                    this.props.onHide();
                }
                else {
                    VenusNotifier.Error("Please select number between 2 and 5", null);
                }
            }
            else {
                VenusNotifier.Error("Please select number between 1 and 5", null);
            }
        } else {
            this.setState({ lastControl: this.state.control });
            this.props.submitButtonClick(this.state.control, this.state.pageNo, this.state.documentGuid);
            this.props.onHide();
        }
    }

    private onToolTipChange = (event: any) => {
        let tempControl = Object.assign({}, this.state.control);
        tempControl.tooltip = event.target.value;
        this.setState({ control: tempControl });
    }

    private onInputNumberChange = (event: any) => {
        let tempControl = Object.assign({}, this.state.control);
        tempControl.tooltip = event.target.value;
        this.props.onUpdateToolTipControl(tempControl);
        this.setState({ control: tempControl });
    }

    private onRequiredChange = (event: any) => {
        this.setState({ lastControl: this.state.control })
        let tempControl = Object.assign({}, this.state.control);
        tempControl.required = event.target.checked;
        this.setState({ control: tempControl });
    }

    private getInputBox = () => {
        if (this.props.controlType && this.props.controlType !== ControlType.Textbox && this.props.dataType !== DataType.Text) {

            if (this.props.controlType === ControlType.SignatureCheckBoxControl) {
                return (
                    <input type="number"
                        className="form-control"
                        placeholder="Enter a number"
                        min={1}
                        max={5}
                        value={this.state.control.tooltip ? this.state.control.tooltip : ""}
                        onChange={this.onInputNumberChange}>
                    </input>
                )
            }
            else {
                return (
                    <input type="number"
                        className="form-control"
                        placeholder="Enter a number"
                        min={2}
                        max={5}
                        value={this.state.control.tooltip ? this.state.control.tooltip : ""}
                        onChange={this.onInputNumberChange}>
                    </input>
                )
            }

        } else {
            return (
                <input type="text"
                    className="form-control"
                    placeholder="Tooltip text"
                    maxLength={200}
                    value={this.state.control.tooltip ? this.state.control.tooltip : ""}
                    onChange={this.onToolTipChange}>
                </input>
            )
        }
    }

    public render() {
        const { content, showState, controlType } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                <Modal show={showState} onHide={this.onCancelButtonClick}>
                    <Modal.Header closeButton>
                        <Modal.Title className='custom-modal-header'>
                            <span
                                className='fas fa-edit'
                                style={{ color: 'grey', marginRight: '5px' }}>
                            </span>
                            {content ? content.header : "Tool Tip"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {controlType === ControlType.Signature ? (
                            <div className="form-group">
                                <label style={{ fontWeight: 700 }}>{content ? content.title : "Add Text"}</label>
                            </div>) : (<>
                                <div className="form-group">
                                    <label style={{ fontWeight: 700 }}>{content ? content.title : "Add Text"}</label>
                                    <i className="fa fa-question-circle" style={{ color: '#DAA520', marginLeft: '10px' }} title={content ? content.tooltipContent : "This text will help the signer to enter the necessary details"}></i>
                                </div>
                                <div style={{ marginBottom: ".5rem" }} className="form-group">
                                    {this.getInputBox()}
                                </div>
                            </>)
                        }
                        <div className="form-group">
                            <CheckBoxComponent
                                id={"isToolTipRequired"}
                                text={"Required"}
                                checked={this.state.control.required}
                                onChange={this.onRequiredChange}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='default'
                            onClick={this.onCancelButtonClick} >
                            <i className='fas fa-times'></i>Cancel
                        </Button>
                        <Button
                            variant='info'
                            onClick={this.onSaveButtonClick}>
                            <i className='fas fa-save'></i>Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}