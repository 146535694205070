import { PdfViewer } from 'awesome-pdf-viewer';
import { ControlBase, PDF_VIEWER_BASE_HEIGHT, PDF_VIEWER_BASE_WIDTH } from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { CustomControl } from 'awesome-pdf-viewer/dist/Controls/CustomControls/CustomControl';
import ControlLayer from 'awesome-pdf-viewer/dist/Layers/ControlLayer';
import { Header } from 'awesome-pdf-viewer/dist/layout/Header';
import { PageProperties, PageSize } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { LeftPanel } from 'awesome-pdf-viewer/dist/layout/LeftPanel';
import { RightPanel } from 'awesome-pdf-viewer/dist/layout/RightPanel';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import { CustomOptions } from 'awesome-pdf-viewer/dist/toolbar/CustomOptions';
import { Pagination } from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import { Toolbar } from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import { PageMode, PdfSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import { isEqual } from 'lodash';
import * as React from 'react';
import { ButtonGroup, Dropdown, OverlayTrigger, Popover, Overlay } from 'react-bootstrap';
import { Guid } from './../../../../../../Core/Utilities/Guid';
import {
    Bookmarks,
    Control, ControlRole, initialControl,
    ControlType, DataType, Form, GroupType,
    OrganizerClient, OrganizerClientType, OrganizerDocument, PdfPageSignatureControl,
    SignatureControlsDictionary,
    SignerDropDown, UserGroup,FormGroup,
    OrganizerProcessPage
} from './../../../../../models/OrganizerComponentModels';
import {
    getControl, getControlIcon,
    getControlIconV1, getControlText, getTopAdjustmentSignatureControlValue
} from './../../../../Helper/OrganizerHelperFunctions';
import { OrganizerBookmarkPanel } from './Panels/OrganizerBookmarkPanel';
import { SignaturePanel } from './Panels/SignaturePanel';
import { SignerTooltipPopup } from 'src/components/common/SignerToolTip';
import { PdfProperties } from '../../../../Helper/PdfHelper'
import { PlaceholderControl } from 'awesome-pdf-viewer/dist/Controls/PlaceholderControl/PlaceholderControl';
import { getControlTopPositionPDFJS } from 'src/components/helper/HelperFunctions';
import { INVALID_PAGE, SignaturePanelConstants } from 'src/components/helper/Constants';

export interface EngagementLetterTabProps {
    document?:OrganizerDocument
    pdf: any;
    organizerClients: OrganizerClient[];
    pages: number[];
    moveToGroup?: (destination: GroupType, pageNo: number) => void;
    bookmarks: Bookmarks;
    signatureControls: SignatureControlsDictionary;
    onDrop: (control: Control, pageNo: number) => void;
    onControlRemove: (control: Control, pageNo: number) => void;
    onControlUpdate: (oldControl: Control, newControl: Control, pageNo: number) => void;
    readOnlyView?: boolean;
    hideMoveTo?: boolean;
    demoClient?: boolean;
    pdfURL: string
    showSignaturePanel?: boolean;
    onSaveToolTip: (control: Control, pageNo: number) => void;
}

export interface EngagementLetterTabState {
    bookmarkExpand: boolean;
    currentPage: number;
    zoomEnabled: boolean;
    clientSignatureExpand: boolean;
    senderSignatureExpand: boolean;
    focusGroup: UserGroup;
    seletedSigner: SignerDropDown;
    selectedSender: SignerDropDown;
    scale: number;
    pageHeight: number;
    showDeleteConfirmationPopover: boolean;
    moveToToggle: boolean;
    deletePopoverTarget: any;
    pdfURL: string;
    currentPageNo: number;
    isRotated: boolean;
    toolTipControl: Control;
    showToolTipPopup: boolean;
    signatureControlType: number,
    signatureDataType: DataType,
    content: {
        header: string,
        title: string,
        tooltipContent: string
    }
}



export default class EngagementLetterTab extends React.Component<EngagementLetterTabProps, EngagementLetterTabState> {
    private _viewPanel: any;
    private _controlLayer: any;
    private _controlList: any[] = [];
    private _toolbar: any;
    private _bookmarkPanel: any;
    private _controlDisplayPanel: any;

    constructor(props: EngagementLetterTabProps) {
        super(props);
        this.state = {
            bookmarkExpand: true,
            currentPage: INVALID_PAGE,
            zoomEnabled: false,
            clientSignatureExpand: true,
            focusGroup: UserGroup.None,
            seletedSigner: {
                value: 0,
                label: "",
                disabled: false,
                role: ControlRole.None
            },
            selectedSender: {
                value: 0,
                label: "",
                disabled: false,
                role: ControlRole.None
            },
            senderSignatureExpand: true,
            scale: PdfProperties.DefaultScale,
            pageHeight: PdfProperties.PageHeight,
            showDeleteConfirmationPopover: false,
            moveToToggle: false,
            deletePopoverTarget: {},
            pdfURL: '',
            currentPageNo: 1,
            isRotated: false,
            toolTipControl: initialControl,
            showToolTipPopup: false,
            signatureControlType: 0,
            signatureDataType: DataType.None,
            content: {
                header: "",
                title: "",
                tooltipContent: ""
            }
        }
    }

    componentDidMount() {
        this.setReferences();
        if (!this.props.demoClient) {
            const selectedClient = this.props.organizerClients.find(client => client.clientType == OrganizerClientType.Taxpayer);
            if (selectedClient?.isDeceased && this.props.organizerClients.length > 1
                && !this.props.organizerClients[1]?.isDeceased) {
                this.setState({
                    seletedSigner: {
                        value: this.props.organizerClients[1]?.id,
                        label: this.props.organizerClients[1]?.name,
                        disabled: this.props.organizerClients[1]?.isDeceased,
                        role: ControlRole.Spouse
                    }
                });
            }
            else
                if (selectedClient) {
                    this.setState({
                        seletedSigner: {
                            value: selectedClient.id,
                            label: selectedClient.name,
                            disabled: selectedClient.isDeceased,
                            role: ControlRole.Taxpayer
                        }
                    })
                }
        } else {
            this.setState({
                seletedSigner: {
                    value: ControlRole.Taxpayer,
                    label: "Taxpayer",
                    disabled: false,
                    role: ControlRole.Taxpayer
                }
            })
        }
        this.setState({
            currentPageNo: 1
        });
    }

    setReferences = () => {
        this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
        this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
        this._viewPanel && this._viewPanel.setControlsReference(this._controlList);
        this._viewPanel && this._viewPanel.setControlLayerReference(this._controlLayer);
        this._controlLayer && this._controlLayer.setControlsReference(this._controlList);
        this._controlLayer && this._controlLayer.setcontrolDisplayPanelReference(this._controlDisplayPanel);
        this._controlDisplayPanel && this._controlDisplayPanel.setControlsReference(this._controlList);
        this._controlDisplayPanel && this._controlDisplayPanel.setViewerReference(this._viewPanel);
        this._viewPanel && this._viewPanel.setBookmarkPanelReference(this._bookmarkPanel);
    }

    componentWillReceiveProps(nextProps: EngagementLetterTabProps) {
        if (nextProps.pages.length === 0) {
            this.setState({
                currentPage: 0
            })
        }
        else if (nextProps.pages.length > 0 &&
            nextProps.pages.length != this.props.pages.length) {
            this.onSelectFirstPage(nextProps.pages);
        }
    }

    shouldComponentUpdate(nextProps: EngagementLetterTabProps, nextState: EngagementLetterTabState) {

        return ((nextProps.pages !== this.props.pages) ||
            (this.state.currentPage === INVALID_PAGE) ||
            (!isEqual(this.state, nextState)));
    }

    componentDidUpdate() {
        this.setReferences();
    }

    toggleBookmarkExpand = () => {
        this.setState(prevState => ({
            bookmarkExpand: !prevState.bookmarkExpand
        }));
    }

    toggleClientSignatureExpand = () => {
        this.setState(prevState => ({
            clientSignatureExpand: !prevState.clientSignatureExpand
        }));
    }

    toggleSenderSignatureExpand = () => {
        this.setState(prevState => ({
            senderSignatureExpand: !prevState.senderSignatureExpand
        }));
    }


    onPaginationClick = (pageNo: number) => {
        this.setState({ currentPage: this.props.pages[pageNo - 1] });
    }

    onFirstPage = () => {
        this._viewPanel.gotoPage(this.props.pages[0]);
        this.setState({
            currentPage: this.props.pages[0],
            currentPageNo: 1
        });
    }

    onLastPage = () => {
        this._viewPanel.gotoPage(this.props.pages[this.props.pages.length - 1]);
        this.setState({
            currentPage: this.props.pages[this.props.pages.length - 1],
            currentPageNo: this.props.pages.length
        });
    }

    onNextPage = () => {
        this._viewPanel.gotoPage(this.props.pages[this.props.pages.indexOf(this.state.currentPage) + 1]);
        this.setState({
            currentPage: this.props.pages[this.props.pages.indexOf(this.state.currentPage) + 1],
            currentPageNo: this.props.pages.indexOf(this.state.currentPage) + 2
        });
    }

    onPreviousPage = () => {
        this._viewPanel.gotoPage(this.props.pages[this.props.pages.indexOf(this.state.currentPage) - 1]);
        this.setState({
            currentPage: this.props.pages[this.props.pages.indexOf(this.state.currentPage) - 1],
            currentPageNo: this.props.pages.indexOf(this.state.currentPage)
        });
    }

    onGotoPage = (pageNo: number) => {
        this._viewPanel.gotoPage(this.props.pages[pageNo - 1]);
        this.setState({
            currentPage: this.props.pages[pageNo - 1],
            currentPageNo: pageNo
        });
    }

    onZoom = (enable: boolean) => {
        this.setState({ zoomEnabled: enable });
    }

    onPageSelect = (pageNo: number) => {
        this._viewPanel.gotoPage(pageNo);
        this.setState({
            currentPage: pageNo,
            currentPageNo: this.props.pages.indexOf(pageNo) + 1
        });

    }


    onPageChange = (pageProps: PageProperties) => {
        this._toolbar.handleZoomChange(this.state.scale);

        const _self = this;
        setTimeout(function () {
            if(this._viewPanel){
                _self._viewPanel.updateControlState(pageProps);
                _self._viewPanel.gotoPage(pageProps.page);
            } else {
                console.warn("this._viewPanel is null or undefined");
            }
            _self.setCurrentPageRotation(_self.state.currentPageNo)
        }, 100);
    }

    setCurrentPageRotation = (page:number) => {
        const currentDocument = this.props.document;
        if(currentDocument) {
            const indexOfEngagementLetter = currentDocument.formGroups?.findIndex((form) => form?.type === GroupType.Engagement);
            if(indexOfEngagementLetter !== INVALID_PAGE) {
                this.setState({isRotated:currentDocument.formGroups[indexOfEngagementLetter]?.forms[page-1]?.rotationDegree === 90})
            }
        }
    }

    getSignatureControls = (): PdfPageSignatureControl => {
        const signatureControls: Control[] = this.props.signatureControls[this.state.currentPage];
        return {
            signatureControls: signatureControls,
            signerId: this.state.seletedSigner.value,
            focusGroup: this.state.focusGroup
        };
    }

    registerDropEvents = () => {
        const _this = this;
        const elements = document.getElementsByClassName("page");
        for (var i = 0; i <= elements.length - 1; i++) {
            const tmpElement: any = elements[i];
            this._toolbar.handleZoomChange(1);
            tmpElement.ondrop = function (ev: any) {
                ev.preventDefault();
                const tmpPageElement = ev.target.offsetParent;
                if (tmpPageElement.attributes["data-page-number"]) {
                    _this.dropSignatureControl(ev);
                }
            };
            tmpElement.ondragover = function (ev: any) {
                ev.preventDefault();
            };
        }
    }

    dragStartSignatureControl = (event: any, controlType: ControlType, dataType: DataType, signerId: number, role: ControlRole) => {
        this.registerDropEvents();
        event.dataTransfer.setData('controlType', controlType.toString());
        event.dataTransfer.setData('dataType', dataType.toString());
        event.dataTransfer.setData('signerId', signerId);
        event.dataTransfer.setData('controlRole', role);

        const element = document.createElement("div");
        element.id = "dragging-element";
        element.style.position = "absolute";
        element.style.top = "-1000px";

        const controlText = getControlText(controlType, dataType);
        const icon = getControlIcon(controlType, dataType);
        element.innerHTML = '<p style="border-style: solid; border-width: 1px;"><i class="'
            + icon + '" style="padding: 5px; background: rgb(240, 240, 241);"></i><label style="font-size: '
            + (12 * this.state.scale).toString() + 'px; font-weight: normal; padding: 0px 5px 0px 5px;">'
            + controlText + '</label></p>';

        document.body.appendChild(element);
        if (event.dataTransfer && typeof event.dataTransfer.setDragImage === 'function') {
            event.dataTransfer.setDragImage(element, 0, 0);
        } else {
            console.warn("setDragImage is not supported in this environment.");
        }
    }

    dragEnd = (event: any) => {
        const element = document.getElementById("dragging-element");
        if (element) {
            element.remove();
        }
    }

    dropSignatureControl = (event: any) => {

        let controlType = event.dataTransfer.getData('controlType');
        let dataType = event.dataTransfer.getData('dataType');
        const signerId = event.dataTransfer.getData('signerId');
        const role = event.dataTransfer.getData('controlRole');

        if (controlType && dataType && signerId) {
            controlType = parseInt(controlType);
            dataType = parseInt(dataType);
            if (!Number.isNaN(controlType) && !Number.isNaN(dataType)) {
                this.addSignatureControl(controlType, dataType, event.offsetX, event.offsetY, signerId, role);
            }
        }
    }

    addSignatureControl = (type: ControlType, dataType: DataType, clientX: number, clientY: number, signerId: number, role: ControlRole) => {
        const { currentPage, pageHeight,isRotated } = this.state;
        const pageElement: any = ControlBase.getPageElement(currentPage);
        const left = ControlBase.getBackendControlLeftPosition(clientX);
        const top = getControlTopPositionPDFJS(currentPage, clientY, 1);

    const control: Control = getControl(type, dataType,left, top);
        control.controlRole = role;
        control.id = Guid.newGuid().toString();
        control.signerId = signerId;
        control.boundingRectangle = {
            left: left,
            top: top,
            width: 150,
            height: 30
        }
        const { Signature } = ControlType;
        if ([Signature].includes(control.controlType)) {
            control.required = true;
            this.showControlPropertyPopup(control);
            this.props.onSaveToolTip(control, this.state.currentPage);
        }
        this.props.onDrop(control, this.state.currentPage);
    }

    private showControlPropertyPopup = (control: Control) => {
        this.setState({
            toolTipControl: control,
            showToolTipPopup: true,
            signatureControlType: control.controlType,
            signatureDataType: control.dataType,
            content: this.getTooltipContent(control.controlType)
        });
    }

    private getTooltipContent = (controlType: number): any => {
        const { Signature } = ControlType;
        switch (controlType) {
            case Signature:
                return SignaturePanelConstants.Signature;
            default:
                return SignaturePanelConstants.Default;
        }
    }

    adjustSamePositionControl = (control: Control): Control => {
        let signatureControls = this.props.signatureControls[this.state.currentPage];
        if (!signatureControls) {
            signatureControls = [];
        }
        const index = signatureControls.findIndex(x => x.id === control.id);
        if (index !== -1) {
            control.boundingRectangle.left += 5;
            control.boundingRectangle.top += 5;
            return this.adjustSamePositionControl(control);
        }
        else {
            return control;
        }
    }

    onControlRemove = (control: Control) => {
        this._controlLayer.removeControl(control.id);
        this.props.onControlRemove(control, this.state.currentPage);
    }

    onControlUpdate = (oldControl: Control, newControl: Control) => {
        this.props.onControlUpdate(oldControl, newControl, this.state.currentPage);
    }

    onSignerChange = (singerId: number) => {
        const selectedClient = this.props.organizerClients.find(client => client.id == singerId);
        if (selectedClient) {
            this.setState({
                seletedSigner: {
                    value: selectedClient.id,
                    label: selectedClient.name,
                    disabled: selectedClient.isDeceased,
                    role: selectedClient.clientType == OrganizerClientType.Taxpayer ? ControlRole.Taxpayer : ControlRole.Spouse
                }
            })
        }
    }

    onSenderChange = (signerGuid: string) => {

    }

    onFocusChange = (focusGroup: UserGroup) => {
        this.setState({
            focusGroup: focusGroup
        });
    }

    getRecievers = (): SignerDropDown[] => {
        return [
            {
                value: ControlRole.Taxpayer,
                label: "Taxpayer",
                role: ControlRole.Taxpayer,
                disabled: false
            },
            {
                value: ControlRole.Spouse,
                label: "Spouse",
                role: ControlRole.Spouse,
                disabled: false
            }
        ]
    }

    onDemoSignerChange = (signerId: number) => {
        this.setState({
            seletedSigner: {
                value: signerId,
                label: ControlRole[signerId].toString(),
                role: signerId,
                disabled: false
            }
        })
    }

    private onDeleteControl = (id: string) => {
        const control: any = this.props.signatureControls[this.state.currentPage].find(x => x.id === id);
        this._controlLayer.removeControl("control_" + id);
        this.props.onControlRemove(control, this.state.currentPage);
    }


    createControls = () => {
        const { currentPage } = this.state;
        const controlCollection: any[] = [];
        this._controlList = [];
        const signatureControls: Control[] = this.props.signatureControls[currentPage];
        const iconStyle: string[] = ['fas'];
        const divStyle: string[] = ['draggable-signature', 'react-draggable'];
        const deleteIconStyle: string[] = ['remove-handle', 'glyphicon', 'glyphicon-remove'];
        if (signatureControls && signatureControls.length) {
            signatureControls
                .forEach((control: Control) => {

                    const pageSize: PageSize = this.getCurrentPageSize();
                    const pdfViewerTop = ControlBase.getPdfViewerControlTopPosition(pageSize.height, control.boundingRectangle.top) - getTopAdjustmentSignatureControlValue(control.controlType);
                    const pdfViewerLeft = ControlBase.getPdfViewerControlLeftPosition(control.boundingRectangle.left);

                    const height = control.boundingRectangle.height == 0 ? 30 : control.boundingRectangle.height;
                    const width = control.boundingRectangle.width == 0 ? 150 : control.boundingRectangle.width;
                    const top = pdfViewerTop;
                    const left = pdfViewerLeft;
                    const controlText = getControlText(control.controlType, control.dataType);
                    switch (control.controlType) {
                        default: {
                            controlCollection.push(
                                <PlaceholderControl
                                    height={height}
                                    id={"control_" + control.id}
                                    key={"control_key_" + control.id}
                                    ref={(ref) => {
                                        this._controlList.push(ref)
                                    }}
                                    page={currentPage}
                                    width={width}
                                    top={top}
                                    left={left}
                                    name={controlText}
                                    helptext={control.tooltip}
                                    disabled={this.props.readOnlyView}
                                    isRequired={control.required}
                                    draggable={!this.props.readOnlyView}
                                    onDragStop={(top: number, left: number) => { this.onDragStop(control.id, top, left) }}>

                                    {this.getControlContent(control, controlText)}

                                    </PlaceholderControl>);
                        }
                    }
                });
        }
            return controlCollection;
    }

    getControlContent = (control: Control, controlText: string): (JSX.Element) => {
        const controlContent = <div className={'draggable-signature-control ' + this.getSelectedStyle(control.controlRole, control.id)}
        style={{ height: "100%", width: "auto", fontSize: `${16 * this.state.scale}px` }} title=''>
        <i className={getControlIcon(control.controlType, control.dataType)}
            style={{ padding: '5px', background: '#f0f0f1' }}></i>{' '}
        {controlText}
        {
            !this.props.readOnlyView && control.controlType === ControlType.Signature &&
            <i
                className='edit-handle fas fa-edit'
                onClick={() => { this.showControlPropertyPopup(control) }}
                title=''
            ></i>
        }    
        {
            !this.props.readOnlyView && <span
                onClick={() => { this.onDeleteControl(control.id) }}
                aria-disabled={false}
                className="remove-handle glyphicon glyphicon-remove"
                style={{ background: "#000", color: "#fff", cursor: 'pointer', fontSize: `${16 * this.state.scale}px`, float: "right", lineHeight: 1, margin: "auto auto auto -10%", position: "relative" }}
                title=''></span>
        }
    </div>;
    return controlContent;
    }

    getSelectedStyle = (role: ControlRole, id: string) => {
        if (role == this.state.seletedSigner.role && !this.props.readOnlyView) {
            ControlBase.focusControl('custom_control_' + id, 'organizer-taxpayer-highlight');
            return 'organizer-taxpayer-highlight';
        }
        else {
            ControlBase.removeFocus('custom_control_' + id, 'organizer-taxpayer-highlight');
            return '';
        }
    }

    setdivStyle = (role: ControlRole, divStyles: string[], id: string): string[] => {
        if (role == this.state.seletedSigner.role && !this.props.readOnlyView) {
            ControlBase.focusControl('custom_control_' + id, 'organizer-taxpayer-highlight');
            return divStyles.concat('organizer-taxpayer-highlight');
        }
        else {
            ControlBase.removeFocus('custom_control_' + id, 'organizer-taxpayer-highlight');
            return divStyles;
        }
    }

    onSelectFirstPage = (newPages: number[]) => {
        this._viewPanel.gotoPage(newPages[0]);
        this.setState({
            currentPage: newPages[0],
            currentPageNo: 1
        });
    }

    private toggleMoveToDropDown = (eventKey: any, event: Object) => {
        if (eventKey === GroupType.Removed.toString()) {
            this.setState({ moveToToggle: true })
        }
        else {
            this.setState({ moveToToggle: !this.state.moveToToggle })
        }
        this._toolbar.forceUpdate();
    }

    private attachMoveToDeletePopoverRef = (target: any) => {
        this.setState({ deletePopoverTarget: target });
    };

    private handleMoveToDelete = () => {
        if (this.props.moveToGroup) {
            this.props.moveToGroup(GroupType.Removed, this.state.currentPage);
            this.hideMoveToDeletePopover();
        }
    }
    private hideMoveToDeletePopover = () => {
        this.setState({ showDeleteConfirmationPopover: false });
        this._toolbar.forceUpdate();
    }

    createMoveTo = () => {
        const { hideMoveTo, moveToGroup, readOnlyView } = this.props;

        const deleteConfirmationPopover = (
            <Popover id="popover-to-delete-confirm" style={{ fontSize: '14px' }}>
                <Popover.Title as="h3" style={{ fontSize: '14px' }}>Confirm Delete</Popover.Title>
                <Popover.Content>
                    {"Are you sure you want to move this page to deleted?"}
                    <div className="popover-footer">
                        <button
                            className="btn btn-only btn-success"
                            onClick={this.handleMoveToDelete}
                            title="Yes"
                            style={{ marginLeft: "5px" }}>
                            Yes
                    </button>
                        <button
                            onClick={this.hideMoveToDeletePopover}
                            title="No"
                            className="btn btn-only btn-danger"
                            style={{ marginLeft: "5px" }}>
                            No
                    </button>
                    </div>
                </Popover.Content>
            </Popover>);

        return <div>{readOnlyView ? null : <ButtonGroup className="zIndex10"
            style={{ visibility: `${hideMoveTo ? 'hidden' : 'visible'}` as React.CSSProperties }} >
             <Dropdown
                show={this.state.moveToToggle}
                onToggle={this.toggleMoveToDropDown}
                rootClose>
                <Dropdown.Toggle
                    style={{ zIndex: 10000 }}
                    id="move-to-dropdown"
                    variant="warning"
                    pullRight={true}>
                    Move To
                            </Dropdown.Toggle>

                <Dropdown.Menu rootCloseEvent="click">

                    {
                        (readOnlyView) ? "" :
                            <Dropdown.Item eventKey={GroupType.Organizer}
                                onClick={() => { moveToGroup && moveToGroup(GroupType.Organizer, this.state.currentPage) }} href="#"
                                style={{ fontSize: '14px' }}>
                                <i className='fas fa-file-alt ddl-icon'
                                    style={{ marginLeft: '0px' }}
                                ></i>Organizers</Dropdown.Item>
                    }

                    {
                        <div>
                            <Dropdown.Item eventKey={GroupType.Removed}
                                onClick={(e: React.MouseEvent) => {
                                    this.setState({ showDeleteConfirmationPopover: true });
                                    e.stopPropagation();
                                }}
                                onSelect={this.toggleMoveToDropDown}
                                style={{ fontSize: '14px' }}
                                ref={this.attachMoveToDeletePopoverRef} href="#"
                            >
                                <i className='fas fa-trash ddl-icon'
                                    style={{ marginLeft: '0px' }}>
                                </i>Delete
                             </Dropdown.Item>
                            <Overlay rootClose={true} onHide={this.hideMoveToDeletePopover}
                                target={this.state.deletePopoverTarget} placement="left"
                                show={this.state.showDeleteConfirmationPopover}
                            >
                                {deleteConfirmationPopover}
                            </Overlay>

                        </div>
                    }
                </Dropdown.Menu>
            </Dropdown>
        </ButtonGroup>}
        </div>
    }

    onDragStop = (id: string, top: number, left: number) => {
        const { isRotated } = this.state
        const draggedControl = this.props.signatureControls[this.state.currentPage].filter(x => x.id == id)[0];
        let newControl = { ...draggedControl };
        const pageSize: PageSize = this.getCurrentPageSize();
        newControl.boundingRectangle.left = ControlBase.getBackendControlLeftPosition(left);
        newControl.boundingRectangle.top = ControlBase.getBackendControlTopPosition(pageSize.height,
            top + getTopAdjustmentSignatureControlValue(newControl.controlType));
        this.onControlUpdate(draggedControl, newControl);
    }

    private onCancelToolTipPopup = () => {
        this.setState({
            toolTipControl: initialControl,
            showToolTipPopup: false,
            signatureControlType: 0,
            signatureDataType: DataType.None
        });
    }

    private onUpdateToolTipPopupControl = (control: Control) => {
        this.setState({
            toolTipControl: control
        });
    }

    public getCurrentPageSize = (): PageSize => {
        const { currentPage } = this.state;
        if (this._viewPanel) {
            return this._viewPanel.getPageSize(currentPage, 1);
        }
        else {
            return PageSize.create(PDF_VIEWER_BASE_HEIGHT, PDF_VIEWER_BASE_WIDTH);
        }
    }

    render() {
        const { pages, bookmarks, readOnlyView,
            organizerClients, pdfURL, demoClient, showSignaturePanel } = this.props;
        const { bookmarkExpand,
            clientSignatureExpand, seletedSigner, currentPageNo
        } = this.state;

        const readOnly = readOnlyView ? true : false;
        const recievers = !demoClient ? organizerClients.map(client => {
            return {
                value: client.id,
                label: client.name,
                disabled: client.isDeceased,
                role: client.clientType == OrganizerClientType.Taxpayer ? ControlRole.Taxpayer : ControlRole.Spouse
            } as SignerDropDown
        }) : this.getRecievers();


        return (<>
            <PdfViewer>
                <Header>
                    <Toolbar
                        ref={(ref: any) => this._toolbar = ref}
                        customToolbarOptions={this.createMoveTo()}
                        customToolbarPosition={'left'}
                        hideReadOnly={true}
                        hideRightPanel={readOnlyView != undefined ? true : false}
                    >

                        <Pagination
                            currentPage={currentPageNo}
                            onFirstPage={this.onFirstPage}
                            onNextPage={this.onNextPage}
                            onPreviousPage={this.onPreviousPage}
                            onLastPage={this.onLastPage}
                            onGotoPage={this.onGotoPage}
                            totalPages={pages.length}
                        >

                        </Pagination>
                        <Zoom>
                        </Zoom>
                        <CustomOptions position="left" />

                    </Toolbar>
                </Header>
                <div className="main" style={{
                    height: 'calc(100vh - 250px)',
                    fontSize: '12px'
                }}>
                    <LeftPanel>
                        <OrganizerBookmarkPanel
                            expanded={bookmarkExpand}
                            toggleExpand={this.toggleBookmarkExpand}
                            bookmarks={bookmarks}
                            onPageSelect={this.onPageSelect}
                        />
                    </LeftPanel>

                    <ControlLayer enableControlsLazyLoad={false} ref={(ref: any) => { this._controlLayer = ref; }} useDefaultNavigationStartControl={false} >
                        {
                            this.createControls()
                        }
                    </ControlLayer>

                    <ViewPanel onDocumentLoad={() => {
                        this.setState({
                            scale: 1,
                            currentPage: this.props.pages[0]
                        })
                    }}
                        ref={(ref: any) => this._viewPanel = ref}

                        onPageChanged={this.onPageChange}
                        onScaleChanged={(scale: number) => {
                            this.setState({
                                scale: scale
                            })
                        }}
                        pageMode={PageMode.SinglePage}
                        defaultPage={this.state.currentPage}
                        pdfSource={PdfSource.createFromUrl(this.props.pdfURL)}
                        disableTextLayer={true}

                    >
                    </ViewPanel>
                    {

                        (!seletedSigner.disabled && showSignaturePanel) &&
                        <RightPanel>
                            {
                                <SignaturePanel
                                    expanded={clientSignatureExpand}
                                    group={UserGroup.Reciever}
                                    title={"e-Signatures"}
                                    toggleExpand={this.toggleClientSignatureExpand}
                                    onDragEnd={this.dragEnd}
                                    onDragStart={this.dragStartSignatureControl}
                                    onSignerChange={(id: any) => {
                                        !demoClient ? this.onSignerChange(id) : this.onDemoSignerChange(id)
                                    }}
                                    selectedSigner={seletedSigner}
                                    signers={recievers}
                                    onFocusChange={this.onFocusChange}
                                    addCustomInputControls={false}
                                />
                            }
                        </RightPanel>
                    }
                </div>
            </PdfViewer>
            <SignerTooltipPopup
                control={this.state.toolTipControl}
                showState={this.state.showToolTipPopup}
                onHide={this.onCancelToolTipPopup}
                onUpdateToolTipControl={this.onUpdateToolTipPopupControl}
                submitButtonClick={this.props.onSaveToolTip}
                initialControl={initialControl}
                content={this.state.content}
                controlType={this.state.signatureControlType}
                dataType={this.state.signatureDataType}
                pageNo={this.state.currentPage}
                documentGuid=""
                pageType={OrganizerProcessPage.EngagementLetter}
            />
        </>);
    }
}


