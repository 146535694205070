import React from 'react'
import { ProductLoadingIcon } from '../assets/SvgIconCollection';

const LoadingView: React.FC = () => (
    <div className='login-container flex-column-center-all'> 
        <ProductLoadingIcon />
        <div className='loading-text' data-title='dot-spin-loader'>
            <div className='dot-spin-loader'></div>
        </div>
        
    </div>
)

LoadingView.displayName = 'LoadingView';

export default LoadingView;
