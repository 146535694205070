import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaFileDownload } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import {
    DownloadDocumentType, EngagementLetterStatus,
    OrganizerDocument, OrganizerStatus, SourceDocumentStatus, OrganizerEvent, OrganizerClient, SignatureStatus
} from '../../../models/OrganizerComponentModels';
import * as Moment from 'moment'
import { SupportedFilePreviewTypes } from '../../../../components/helper/Constants';
import { GetFormatedDateTime } from '../../../../components/helper/HelperFunctions';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select';
import { actionCreators as DeliveredOrganizerStore } from '../../../store/DeliveredOrganizerStore';
import { useDispatch } from 'react-redux';

interface OrganizerDownloadModalProps {
    show: boolean;
    onClose: () => void;
    downloadFilledDocument: (documentGuid: string, year: number, clientId: string, log: boolean, callback?: (data: any) => void) => void;
    downloadSignedEngagementLetter: (documentGuid: string, year: number, clientId: string, log: boolean, callback?: (data: any) => void) => void;
    downloadUploadedFile: (documentId: number, fileName: string, documentGuid: string, year: number, clientId: string, callback?: (data: any) => void) => void;
    downloadAll: (apiUrl: string, documentId: number, documentGuid: string, year: number, clientId: string, logOrganizer: boolean, logEngage: boolean, fileNames: string[], callback?: () => void) => void;
    refreshOnDownload: (id: number) => void;
    loading: boolean;
    isArchived?: boolean;
    model: OrganizerDocument;
    getSourceDocumentMetadataAsync?: (filename: string, documentGuid: string, year: number) => void;
    downloadSourceDocuments: (apiUrl: string, documentId: number, year: number, clientId: string, fileNames: string[], documentGuid: string, isDownloadAllSourceDocuments: boolean) => void;
}

const OrganizerDownloadModal: React.FC<OrganizerDownloadModalProps> = ({ show, onClose, loading, isArchived, downloadFilledDocument,
    model: { processInfoGuid, clientId, taxYear, organizerStatus, previousOrganizerStatus, engagementLetterStatus, sourceDocumentStatus, uploadedFileNames, id, downloadDocuments, clientTracking }, downloadSignedEngagementLetter,
    downloadUploadedFile, downloadAll, getSourceDocumentMetadataAsync, downloadSourceDocuments, refreshOnDownload
}) => {
    const dispatch = useDispatch();
    const dateFormatter = (cell: any, row: any) => {
        return cell ? <span title={GetFormatedDateTime(cell)} className="ellipsis">{GetFormatedDateTime(cell)}</span> : '';
    }

    const actedByFormatter = (cell: any, row: any) => {
        return <span title={row.downloadedBy} className="ellipsis">{row.downloadedBy}</span>;
    }
    const previewButton = (cell: any, row: any) => {
        return <span onClick={() => onPreviewDocumentClick(id, row.fileName, processInfoGuid, taxYear, clientId)} className="od-modal-preview-icon-container" title="Preview Document">
            <i className="fas fa-eye od-modal-preview-icon"></i>
        </span>;
    }
    const fileNameFormatter = (cell: any, row: any) => {
        return (
            <Link
                key={row.fileName}
                to={'#'}
                onClick={() => downloadUploadedFile(id, row.fileName, processInfoGuid, taxYear, clientId, () => {
                    refreshOnDownload(id);
                })}
                className="SourceDocument delivered-return-download-link ellipsis"
                title={row.fileName}
            >
                {row.fileName}
            </Link>
        )
    }
    const filledOrganizerDownloadLinkDisable = () => {
        let statusToBeChecked = organizerStatus !== OrganizerStatus.ClosedByFirm ? organizerStatus : previousOrganizerStatus;

        if (statusToBeChecked === OrganizerStatus.None ||
            statusToBeChecked === OrganizerStatus.Delivered ||
            statusToBeChecked === OrganizerStatus.Dropped
        ) {
            return true;
        }
        return false;
    }

    const engagementDownloadLinkDisable = engagementLetterStatus !== EngagementLetterStatus.ESigned &&
        engagementLetterStatus !== EngagementLetterStatus.EngagementLetterDownloaded &&
        engagementLetterStatus !== EngagementLetterStatus.PartiallySigned &&
        engagementLetterStatus !== EngagementLetterStatus.Reviewed &&
        engagementLetterStatus !== EngagementLetterStatus.PartiallyReviewed &&
        engagementLetterStatus !== EngagementLetterStatus.ManuallySigned;

    const sourceDocumentDownloadLinkDisable = sourceDocumentStatus == SourceDocumentStatus.AwaitingUpload ||
        sourceDocumentStatus == SourceDocumentStatus.None;

    const logOrganizer = () => {
        let statusToBeChecked = organizerStatus !== OrganizerStatus.ClosedByFirm ? organizerStatus : previousOrganizerStatus;
        if (statusToBeChecked === OrganizerStatus.Completed ||
            statusToBeChecked === OrganizerStatus.OrganizerDownloaded ||
            statusToBeChecked === OrganizerStatus.ManuallyCompleted ||
            statusToBeChecked === OrganizerStatus.QuestionnaireCompleted) {
            return true;
        }
        return false;
    }

    const hideOrganizerDownloadInfoOnPopup = (organizerStatus === OrganizerStatus.ClosedByFirm || organizerStatus === OrganizerStatus.QuestionnaireCompleted) && previousOrganizerStatus !== OrganizerStatus.OrganizerDownloaded;

    let logEngage = (engagementLetterStatus === EngagementLetterStatus.ESigned ||
        engagementLetterStatus === EngagementLetterStatus.EngagementLetterDownloaded ||
        engagementLetterStatus === EngagementLetterStatus.Reviewed ||
        engagementLetterStatus === EngagementLetterStatus.ManuallySigned);


    const organizerDownloadColumn = [
        {
            header: "Uploaded Date",
            dataSort: true,
            key: 'uploadDate',
            isKey: false,
            dataFormat: dateFormatter,
            toolTip: true,
            width: '123px',
            columnClassName: '',

        },
        {
            header: 'File Name',
            dataSort: false,
            key: 'fileName',
            isKey: true,
            dataFormat: fileNameFormatter,
            toolTip: true,
            width: '235px',
            columnClassName: '',
        },
        {
            header: 'Downloaded By',
            dataSort: false,
            key: 'downloadedBy',
            isKey: false,
            dataFormat: actedByFormatter,
            toolTip: true,
            width: 'auto',
            columnClassName: '',
        },
        {
            header: 'Downloaded Time',
            dataSort: false,
            key: 'downloadedTime',
            isKey: false,
            dataFormat: dateFormatter,
            toolTip: true,
            width: '123px',
            columnClassName: '',
        },
        {
            header: 'Preview',
            dataSort: false,
            key: 'preview',
            isKey: false,
            dataFormat: previewButton,
            toolTip: false,
            width: '57px',
            columnClassName: '',
        }
    ];

    if (!downloadDocuments) {
        downloadDocuments = [];
    }

    const organizerDetails = downloadDocuments.find(doc => {
        return doc.type === DownloadDocumentType.Organizer
    });

    const engagementLetterDetails = downloadDocuments.find(doc => {
        return doc.type === DownloadDocumentType.EngagementLetter
    });

    const sourceDocuments = downloadDocuments.filter(doc => {
        return doc.type === DownloadDocumentType.SourceDocument
    })

    const getDateFromUtc = (utcDate: any) => {
        return Moment.utc(utcDate).local().format('MM/DD/YYYY')
    }

    const getTimeFromUtc = (utcDate: any) => {
        return Moment.utc(utcDate).local().format('hh:mm:ss A')
    }

    const manuallySignedEvent = clientTracking && clientTracking.find(ct => {
        return ct.eventId === OrganizerEvent.CpaEngagementLetterManuallySigned
    });

    const manuallyCompletedEvent = clientTracking && clientTracking.find(ct => {
        return ct.eventId === OrganizerEvent.CpaOrganizerManuallyCompleted
    });

    const reopenOrganizerEvent = clientTracking && clientTracking.findLast(ct => {
        return ct.eventId === OrganizerEvent.ReopenOrganizer
    });

    function filledOrganizerModalStatus() {
        if (manuallyCompletedEvent && !reopenOrganizerEvent) {
            return true;
        }
        else if (manuallyCompletedEvent && reopenOrganizerEvent) {
            return manuallyCompletedEvent.actedOn > reopenOrganizerEvent.actedOn;
        }
        else {
            return false;
        }
    };

    const onPreviewDocumentClick = (id: number, fileName: string, processInfoGuid: string, taxYear: number, clientId: string) => {
        if (getSourceDocumentMetadataAsync) {
            getSourceDocumentMetadataAsync(fileName, processInfoGuid, taxYear);
        }
        if (SupportedFilePreviewTypes && !SupportedFilePreviewTypes.includes("." + fileName.split('.').pop()?.toLowerCase())) {
            downloadUploadedFile(id, fileName, processInfoGuid, taxYear, clientId)
        }
    }
    let checkClientSignatureStatus = (
        signedDocumentCallback?: (documentGuid: string, year: number, clientId: string, log: boolean, callback?: () => void) => void
        , downloadAllCallback?: (apiUrl: string, documentId: number, documentGuid: string, year: number, clientId: string, logOrganizer: boolean,
            hideOrganizerDownloadInfoOnPopup: boolean, logEngage: boolean, fileNames: string[], callback?: () => void) => void) => {
        dispatch(DeliveredOrganizerStore.requestOrganizerClients(id, (clients: OrganizerClient[]) => {
            if (clients?.length === 2) {
                if (engagementLetterStatus === EngagementLetterStatus.Reviewed || engagementLetterStatus === EngagementLetterStatus.PartiallySigned) {
                    const secondSignatory = clients?.find(doc => doc.signingOrder === 2);
                    if (secondSignatory?.signatureStatus === SignatureStatus.None && secondSignatory?.documentSigner &&
                        !secondSignatory?.isDeceased) {
                        logEngage = false;
                    }
                }
            }
            if (signedDocumentCallback) {
                signedDocumentCallback(processInfoGuid, taxYear, clientId, logEngage, () => { refreshOnDownload(id) })
            }
            if (downloadAllCallback) {
                downloadAllCallback(isArchived ? "Document/Archived/DownloadAll" : "Document/Delivered/DownloadAll", id, processInfoGuid, taxYear, clientId, logOrganizer(), logEngage, fileNames, () => { refreshOnDownload(id) });
            }
        }));
    }
    const onSignedDocumentsDownloadClick = () => {
        checkClientSignatureStatus(downloadSignedEngagementLetter);
    }
    const onDownloadAllClick = () => {
        checkClientSignatureStatus(undefined, downloadAll);
    }

    let fileNames: string[] = [];
    if (sourceDocuments && sourceDocuments.length) {
        sourceDocuments.map(doc => {
            fileNames.push(doc.fileName);
        });
    }

    const organizerDownloadData = sourceDocuments.length ? sourceDocuments.map((model, index) => {
        return {
            uploadDate: model.uploadedOn,
            fileName: model.fileName,
            downloadedBy: model.downloadBy,
            downloadedTime: model.downloadDate,
        }
    }) : [];
    const hasDocumentsToDownload = sourceDocuments && sourceDocuments.length && sourceDocuments.some(c => (!c.downloadDate || !c.downloadBy));
    const newUploadedSourceDocumentsFiles: string[] = (sourceDocuments && sourceDocuments.length) ?
        sourceDocuments.filter(c => (!c.downloadDate || !c.downloadBy))?.map(x => x.fileName) : [];

    let modalBody = (sourceDocuments && sourceDocuments.length) ?
        (<BootstrapTable data={organizerDownloadData}
            maxHeight={"182px"}
        >
            {organizerDownloadColumn.map((value, index) => {
                return <TableHeaderColumn
                    key={index}
                    isKey={value.isKey}
                    width={value.width}
                    dataSort={value.dataSort}
                    dataField={value.key}
                    dataFormat={value.dataFormat}
                    columnClassName={value.columnClassName}>
                    {value.header}
                </TableHeaderColumn>;
            })}
        </BootstrapTable>) : '';

    return <Modal className="download-organizer-modal" show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>
                <FaFileDownload className='modalIcon' />
                Download Documents
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <LoadingOverlay style={{ height: '100%' }}>
                <ul style={{ padding: 10 }}>
                    <h3>Organizer</h3>
                    <Link
                        to={'#'}
                        onClick={() => downloadFilledDocument(processInfoGuid, taxYear, clientId, logOrganizer(), () => { refreshOnDownload(id) })}
                        className={`delivered-return-download-link ${filledOrganizerDownloadLinkDisable() ? "list-disabled" : ""}`}
                    >
                        Filled Organizer

                    </Link>
                    {
                        filledOrganizerModalStatus()
                            ? <span className="download-details"> | Changed Status to Manually Completed on {getDateFromUtc(manuallyCompletedEvent.actedOn)} at {getTimeFromUtc(manuallyCompletedEvent.actedOn)} </span>
                            : (organizerDetails && !hideOrganizerDownloadInfoOnPopup &&
                                <span className="download-details"> | Downloaded by {organizerDetails.downloadBy} on {getDateFromUtc(organizerDetails.downloadDate)} at {getTimeFromUtc(organizerDetails.downloadDate)}</span>)
                    }
                    {
                        engagementLetterStatus !== EngagementLetterStatus.NA &&
                        <div style={{ paddingTop: '15px' }}>
                            <h3>Documents to Sign</h3>
                            <Link
                                to={'#'}
                                onClick={() => onSignedDocumentsDownloadClick()}
                                className={`SignedEngagementLetter delivered-return-download-link ${engagementDownloadLinkDisable ? "list-disabled" : ""}`}
                            >
                                Signed Documents

                            </Link>
                            {
                                manuallySignedEvent
                                    ? <span className="download-details"> | Changed Status to Manually Signed on {getDateFromUtc(manuallySignedEvent.actedOn)} at {getTimeFromUtc(manuallySignedEvent.actedOn)} </span>
                                    : (engagementLetterDetails &&
                                        <span className="download-details"> | Downloaded by {engagementLetterDetails.downloadBy} on {getDateFromUtc(engagementLetterDetails.downloadDate)} at {getTimeFromUtc(engagementLetterDetails.downloadDate)} </span>)
                            }
                        </div>
                    }
                    {
                        !sourceDocumentDownloadLinkDisable && sourceDocuments && sourceDocuments.length > 0 &&
                        <div style={{ paddingTop: '30px' }}>
                            <div className="source-documents">
                                <h3>Source Documents</h3>
                                <Select
                                    placeholder='Download'
                                    className='custom-download-type'
                                    options={[
                                        {
                                            value: false,
                                            label: (
                                                <>
                                                    {hasDocumentsToDownload
                                                        ?
                                                        <span>Download Newly Added</span>
                                                        :
                                                        <span style={{ cursor: "not-allowed" }} title="No New Documents to Download">Download Newly Added</span>
                                                    }
                                                </>
                                            ) as any,
                                            disabled: !hasDocumentsToDownload
                                        },
                                        {
                                            value: true,
                                            label: 'Download All'
                                        }

                                    ]}
                                    onChange={(option: any) => {
                                        downloadSourceDocuments(isArchived ? "Document/Archived/DownloadSourceDocuments" : "Document/Delivered/DownloadSourceDocuments", id, taxYear, clientId, option.value ? fileNames : newUploadedSourceDocumentsFiles, processInfoGuid, option.value)
                                    }}
                                    clearable={false}
                                    searchable={false}
                                />
                            </div>

                            {
                                <div style={{ marginTop: '2px' }}>
                                    {modalBody}

                                </div>
                            }

                        </div>
                    }
                </ul>
                <Loader loading={loading} text={"Loading..."} />
            </LoadingOverlay>
        </Modal.Body>

        <Modal.Footer>
            <Button
                variant="default"
                className="btn-white"
                onClick={onClose} >
                <i className="glyphicon glyphicon-remove" />Cancel
            </Button>
            <Button
                variant='info'
                disabled={sourceDocumentDownloadLinkDisable && filledOrganizerDownloadLinkDisable() && engagementDownloadLinkDisable}
                onClick={(e: any) => { onDownloadAllClick() }}>
                <i className='glyphicon glyphicon-download-alt'></i>Download All
            </Button>
        </Modal.Footer>
    </Modal>
}


export { OrganizerDownloadModal }

