import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { actionCreators as CustomQuestionsActionCreators } from "src/store/settings/CustomQuestions/CustomQuestionsStore";
import { CustomQuestionsPlaceHolderIcon } from "src/assets/SvgIconCollection";
import { ApplicationState } from "src/store";
import SectionPreview from "src/Organizer/components/Settings/CustomQuestions/Preview/Parts/SectionPreview";
import { ICustomQuestion, ITemplate, ISection } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import { CustomQuestionsTabConstants, TemplateModalConstants } from "src/components/helper/Constants";

type CustomQuestionsProps = {
    templates: any;
    selectedTemplate: ISelectedTemplate;
    isEditable: boolean;
    onSetSelectedTemplate: (template: ISelectedTemplate) => void;
    onTemplateUpdate: (template: ISelectedTemplate) => void;
};

interface ICustomQuestionTemplate {
    formId: number,
    template: ITemplate,
    title: string,
    description: string,
    isDeleted: boolean,
    sections: ISection[],

}
interface ISelectedTemplate {
    formId?: number,
    template?: ITemplate,
    title: string,
    label: string,
    value: number,
    description: string,
    isDefault?: boolean,
    isDeleted?: boolean,
    sections: ISection[]
}

const CustomQuestionsTab: FC<CustomQuestionsProps> = ({ templates, selectedTemplate, isEditable, onSetSelectedTemplate, onTemplateUpdate }) => {
    const dispatch = useDispatch();
    const [templatesList, setTemplatesList] = useState<ISelectedTemplate[]>(templates);
    const {isLoading:intialCQLoad} = useSelector((state: ApplicationState) => state.customQuestions);
    const [isLoading, setIsLoading] = useState(false);

    React.useEffect(() => {
        setTemplatesList(templates);
    }, [templates]);

    const onGetSelectedCustomQuestionCallback = (template: ICustomQuestionTemplate) => {
        let selectedTemplate: ISelectedTemplate = { ...template, label: template.template?.name, value: template.template?.templateId };
        onSetSelectedTemplate(selectedTemplate);
        setIsLoading(false);
    }

    const onFailureCallback = () => {
        setIsLoading(false);
    }

    const onTemplateSelectionChange = (event) => {
        if (event.value !== -1) {
            setIsLoading(true);
            dispatch(CustomQuestionsActionCreators.getCustomQuestion(event.value, onGetSelectedCustomQuestionCallback, onFailureCallback));
        }
        else {
            onSetSelectedTemplate(templatesList[0]);
        }
    }

    const onSectionDelete = (sectionTodelete) => {
        if (selectedTemplate) {
            let sections: ISection[] = selectedTemplate.sections;
            if (sections?.length > 1) {
                sections.forEach((section, index) => {
                    if (sectionTodelete.sectionId === section.sectionId) {
                        sections?.splice(index, 1);
                    }
                })
            }
            onTemplateUpdate(selectedTemplate);
        }
    }
    const onQuestionDelete = (section) => {
        if (selectedTemplate) {
            let index = selectedTemplate.sections?.indexOf(section);
            if (section.sectionItems.length > 0) {
                selectedTemplate.sections[index] = section;
            }
            else {
                selectedTemplate.sections.splice(index, 1);
            }
        }
        onTemplateUpdate(selectedTemplate);
    }
    return (
        <div className={"custom-questions-tab-main-container"}>
            <div className="custom-questions-tab-header-container">
                <label className="dropdown-title">{CustomQuestionsTabConstants.ChooseTemplate}</label>
                <Select className="custom-questions-tab-templates-dropdown"
                    options={templatesList}
                    value={(selectedTemplate && selectedTemplate.label !== TemplateModalConstants.CustomTemplate) ? { ...selectedTemplate, title: selectedTemplate?.template?.name } : null}
                    onChange={onTemplateSelectionChange}
                    clearable={false}
                    searchable={false}
                    disabled={!isEditable}
                    placeholder={(selectedTemplate && selectedTemplate.label === TemplateModalConstants.CustomTemplate) ? TemplateModalConstants.CustomTemplate : CustomQuestionsTabConstants.SelectTemplate}
                />
            </div>
            <LoadingOverlay style={{ height: '100%' }}>
                {selectedTemplate && selectedTemplate.value > 0 ?
                    <div className="custom-questions-tab-template-container">
                        <div className="questionnaire-header-container">
                            <div className="title">
                                {selectedTemplate?.title}
                            </div>
                            <div className="description">
                                <div className="description">
                                    {selectedTemplate?.description}
                                </div>
                            </div>
                        </div>

                        {selectedTemplate &&
                            selectedTemplate.sections?.length > 0 &&
                            selectedTemplate.sections.map((section: any, sectionIndex) => (
                                <SectionPreview
                                    sectionIndex={sectionIndex}
                                    section={section}
                                    sectionsCount={selectedTemplate.sections.length}
                                    isEditMode={true}
                                    isEditable={isEditable}
                                    onSectionDelete={onSectionDelete}
                                    resetValidations={false}
                                    onQuestionDelete={onQuestionDelete} />
                            ))}
                    </div> : <div className="custom-questions-tab-placeholder-container">
                        <CustomQuestionsPlaceHolderIcon />
                        <label>{CustomQuestionsTabConstants.NoQuestionnaiers}</label>
                        <label>{CustomQuestionsTabConstants.GetStarted}</label>
                    </div>
                }
                  <Loader loading={isLoading || intialCQLoad } />
            </LoadingOverlay>
        </div >
    )
}
export default CustomQuestionsTab;