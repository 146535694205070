import { createUserManager, createUserManagerWithRedirect } from './helpers'
import store from '../configureStore';
import { resetUserCache } from '../store/auth/auth.apis'
import { createForethoughtCookie, resetCookie } from '../components/helper/HelperFunctions'

export var userManager = createUserManager()

export const signinRedirect = () => userManager.signinRedirect()

export const signinRedirectWithCallbackArgs = (args: string) => {
    if (args) {
        const redirectUri = userManager.settings.redirect_uri + "?callback_args=" + encodeURIComponent(args)
        userManager = createUserManagerWithRedirect(redirectUri);
    }
    userManager.signinRedirect()
}

export const signinRedirectCallback = () => userManager.signinRedirectCallback()

export async function signoutRedirect() {
    createForethoughtCookie("isForethoughtWidgetVisible", "false");
    await resetUserCache();
    resetCookie('userAutoLogout', '0');
    userManager.signoutRedirect({
        id_token_hint: store.getState().auth.user?.id_token,
        post_logout_redirect_uri: window.location.origin + "/signout-callback-oidc",
    });
}

export const signoutRedirectCallback = () => userManager.signoutRedirectCallback()

export async function getAccessToken() {
    const user = store.getState().auth.user
    return user?.access_token
}
